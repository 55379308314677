import { useEffect, useState, useContext } from "react";
import { globalCarService } from "../../Services/GlobalCarService";
import BootstrapTable from "react-bootstrap-table-next";
import CardWrap from "../Common/CardWrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Loader from "../Common/Loader";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../Contexts/GlobalContext";
import { AuthContext } from "../../Contexts/AuthContext";
import { roles } from "../../Config/Roles";

import * as dateHelper from "../../Helpers/DateHelper";

const GlobalCarList = () => {
  const { t: translator } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [cars, setCars] = useState([]);
  const { isMobile } = useContext(GlobalContext);
  const { roleId } = useContext(AuthContext);
  const { superadmin, admin } = roles;
  const [update, setUpdate] = useState(new Date());
  const [deleteModal, setDeleteModal] = useState({
    item: null,
    show: false,
  });
  const [resultModal, setResultModal] = useState({
    show: false,
    title: "",
    text: "",
  });
  const [search, setSearch] = useState({
    companyId: 0,
  });

  useEffect(() => {
    globalCarService
      .getCarsEndProcessing()
      .then((res) => {
        setCars(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, [update, search]);

  const { SearchBar } = Search;

  function actionFormatter(cell, row) {
    return <div></div>;
  }

  const columns = [
    {
      dataField: "id",
      text: "",
      formatter: actionFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "50px", textAlign: "center" };
      },
    },
    {
      dataField: "datePurchased",
      text: `${translator("GlobalCarTableDatePurchased")}`,
      formatter: dateFormatter,
      sort: true,
      hidden: Boolean(isMobile),
    },
    {
      dataField: "pruchasedFrom",
      text: `${translator("GlobalCarTablePruchasedFrom")}`,
      sort: true,
      hidden: Boolean(isMobile),
    },
    {
      dataField: "lotNumber",
      text: `${translator("CarTableLotNumber")}`,
      sort: true,
    },
    {
      dataField: "regNumber",
      text: `${translator("CarTableRegNum")}`,
      sort: true,
    },
    {
      dataField: "brand",
      text: `${translator("GlobalCarTableBrend")}`,
      sort: true,
      hidden: Boolean(isMobile),
    },
    {
      dataField: "model",
      text: `${translator("GlobalCarTableModel")}`,
      sort: true,
      hidden: Boolean(isMobile),
    },
    {
      dataField: "colour",
      text: `${translator("GlobalCarTableColor")}`,
      sort: true,
      hidden: Boolean(isMobile),
    },
    {
      dataField: "engineCode",
      text: `${translator("GlobalCarTableEngineCode")}`,
      sort: true,
      hidden: Boolean(isMobile),
    },
    {
      dataField: "mileage",
      text: `${translator("GlobalCarTableMileage")}`,
      sort: true,
      hidden: Boolean(isMobile),
    },
    {
      dataField: "bodyType",
      text: `${translator("GlobalCarTableBodyType")}`,
      sort: true,
      hidden: Boolean(isMobile),
    },
    {
      dataField: "paintCode",
      text: `${translator("GlobalCarTablePaintCode")}`,
      sort: true,
      hidden: Boolean(isMobile),
    },
    {
      dataField: "id",
      text: `${translator("Action")}`,
      formatter: linkFormatter,
      hidden: Boolean(isMobile),
    },
  ];
  function dateFormatter(cell, row) {
    return dateHelper.formatUtcToDate(row.datePurchased);
  }
  const backInList = (id) => {
    globalCarService
      .addInProcessing(id)
      .then((res) => {
        setUpdate(new Date());
      })
      .catch((err) => console.log(err));
  };
  const rowEvents = {
    onClick: (e, row) => {
      e.preventDefault();      
      if (e.target.classList.contains("action2")) {
        //history.push(`/add-in-processing/${row.id}`);
        backInList(row.id);
        return;
      }
      history.push(`/update-global-car/${row.id}`);
    },
  };
  function linkFormatter(cell, row) {
    return (
      <a className="action2" style={{ display: "block" }}>
        Back
      </a>
    );
  }
  return (
    <CardWrap title={translator("CarOverview")}>
      <ToolkitProvider keyField="id" data={cars} columns={columns} search>
        {(props) => (
          <>
            <SearchBar
              {...props.searchProps}
              placeholder={translator("Search")}
            />

            {isLoading ? (
              <Loader />
            ) : (
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory()}
                noDataIndication={translator("NoDataIndication")}
                rowEvents={rowEvents}
                hover
              />
            )}
          </>
        )}
      </ToolkitProvider>
    </CardWrap>
  );
};

export default GlobalCarList;
