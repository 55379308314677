import { globalCarUrl } from "../Config/Url";
import axios from "axios";

export const globalCarService = {
  getCars,
  deleteCar,
  addCar,
  getById,
  updateCar,
  getCarsInProcessing,
  getCarsEndProcessing,
  changeOrder,
  finishProcessing,
  addInProcessing,
  backInOverview
};

async function getCars(isArrived,locationId) {
  return axios
    .get(`${globalCarUrl}?locationId=${locationId}&isArrived=${isArrived ? isArrived : ""}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function getCarsInProcessing() {
  return axios
    .get(`${globalCarUrl}/inProcessing`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function getCarsEndProcessing() {
  return axios
    .get(`${globalCarUrl}/endProcessing`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function deleteCar(id) {
  return axios
    .delete(`${globalCarUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function getById(id) {
  return axios
    .get(`${globalCarUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function addCar(data) {
  var formData = new FormData();

  formData.append("DatePurchased", new Date(data.datePurchased).toUTCString());
  formData.append("PruchasedFrom", data.pruchasedFrom);
  formData.append("LotNumber", data.lotNumber);
  formData.append("RegNumber", data.regNumber);
  formData.append("Brand", data.brand);
  formData.append("Model", data.model);
  formData.append("Colour", data.colour);
  formData.append("EngineCode", data.engineCode);
  formData.append("Mileage", data.mileage);
  formData.append("BodyType", data.bodyType);
  formData.append("PaintCode", data.paintCode);
  formData.append("IsArrived", data.isArrived);
  formData.append("LocationId", data.locationId.value);

  let arrayTest = Array.from(data.images);
  arrayTest.forEach((element) => {
    formData.append("Images", element);
  });

  return axios
    .post(globalCarUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res)
    .catch((err) => err.response);
}

async function updateCar(id, data) {
  var formData = new FormData();

  formData.append(
    "Data.DatePurchased",
    new Date(data.datePurchased).toUTCString()
  );
  formData.append("Data.PruchasedFrom", data.pruchasedFrom);
  formData.append("Data.LotNumber", data.lotNumber);
  formData.append("Data.RegNumber", data.regNumber);
  formData.append("Data.Brand", data.brand);
  formData.append("Data.Model", data.model);
  formData.append("Data.Colour", data.colour);
  formData.append("Data.EngineCode", data.engineCode);
  formData.append("Data.Mileage", data.mileage);
  formData.append("Data.BodyType", data.bodyType);
  formData.append("Data.PaintCode", data.paintCode);
  formData.append("Data.IsArrived", data.isArrived);
  formData.append("Data.LocationId", data.locationId.value);

  let arrayTest = Array.from(data.images);
  arrayTest.forEach((element) => {
    formData.append("Data.Images", element);
  });

  return axios
    .put(`${globalCarUrl}/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res)
    .catch((err) => err.response);
}

async function changeOrder(data) {
  return axios
    .put(`${globalCarUrl}/changeOrder`, { data })
    .then((res) => res)
    .catch((err) => err.response);
}

async function finishProcessing(id) {
  return axios
    .put(`${globalCarUrl}/${id}/finish`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function addInProcessing(id) {
  return axios
    .put(`${globalCarUrl}/${id}/add-in-processing`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function backInOverview(id) {
  return axios
    .put(`${globalCarUrl}/${id}/back-in-overview`)
    .then((res) => res)
    .catch((err) => err.response);
}
