import { useEffect, useState, useContext } from "react";
import { creditNoteService } from "../../Services/CreditNoteService";
import BootstrapTable from "react-bootstrap-table-next";
import CardWrap from "../Common/CardWrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Button } from "react-bootstrap";
import Loader from "../Common/Loader";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import * as dateHelper from "../../Helpers/DateHelper";
import { GlobalContext } from "../../Contexts/GlobalContext";
import { AuthContext } from "../../Contexts/AuthContext";
import { roles } from "../../Config/Roles";
import ResultModal from "../Common/ResultModal";
import DeleteModal from "../Common/DeleteModal";
import { companyService } from "../../Services/CompanyService";
import Select from "react-select";
import DatePicker from "react-datepicker";

const CreditNoteList = ({ location }) => {
  console.log();
  const { t: translator } = useTranslation();
  const { isMobile } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(true);
  const [update, setUpdate] = useState(new Date());
  const [resultModal, setResultModal] = useState({
    show: false,
    title: "",
    text: "",
  });
  const [creditNotes, setCreditNotes] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [deleteModal, setDeleteModal] = useState({
    item: null,
    show: false,
  });
  
  const { roleId } = useContext(AuthContext);
  const [search, setSearch] = useState({
    companyId: 0,
    from: "",
    to: ""  
  });
  const { superadmin, admin } = roles;
  const history = useHistory();

  useEffect(() => {
    console.log(location.search);
    Promise.all([
      creditNoteService.getCreditNotes(
        search.companyId,
        search.from,
        search.to
      ),
      companyService.getCompanies(),
    ])
      .then((res) => {
        setCreditNotes(res[0].data.data);
        setCompanies(
          res[1].data.data.map((i) => ({ value: i.id, label: i.name }))
        );
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, [update, search]);

  const handleToAdd = () => {
    history.push("/add-credit-note");
  };

  function dateFormatter(cell, row) {
    return dateHelper.formatUtcToDate(row.creditNoteDate);
  }
  function currencyFormatter(cell, row) {
    return new Intl.NumberFormat("en", {
      style: "currency",
      currency: "GBP",
    }).format(cell);
  }

  const handleSubmitDeleteModal = () => {
    creditNoteService
      .deleteCreditNote(deleteModal.item)
      .then((res) => {
        setDeleteModal({ item: null, show: false });
        res.data.success
          ? setResultModal({
              show: true,
              title: "Success",
              text: "SuccessText",
            })
          : setResultModal({
              show: true,
              title: "Error",
              text: "ErrorText",
            });
      })
      .catch((err) => console.log(err));
  };

  const { SearchBar } = Search;

  function actionFormatter(cell, row) {
    return (
      <div className="action">
        <i
          className="pe-7s-trash"
          style={{ color: "#c00", fontWeight: "bold", fontSize: 15 }}
        ></i>
      </div>
    );
  }
  function successFormatter(cell, row) {
    return cell == false ? null : (
      <div className="action">
        <i
          className="pe-7s-check"
          style={{ color: "#029688", fontWeight: "bold", fontSize: 15 }}
        ></i>
      </div>
    );
  }

  const columns = [
    {
      dataField: "id",
      text: "",
      formatter: actionFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "50px", textAlign: "center" };
      },
    },
    {
      dataField: "creditNoteNumber",
      text: `${translator("CreditNoteTableNumber")}`,
      sort: true,
    },
    {
      dataField: "creditNoteDate",
      text: `${translator("CreditNoteTableDate")}`,
      formatter: dateFormatter,
      sort: true,
      hidden: Boolean(isMobile),
    },
    {
      dataField: "companyName",
      text: `${translator("CreditNoteTableCompany")}`,
      sort: true,
      hidden: Boolean(isMobile),
    },    
    {
      dataField: "paymentType",
      text: `${translator("CreditNoteTablePaymentType")}`,
      sort: true,
      hidden: Boolean(isMobile),
    },
    {
      dataField: "totalVat",
      formatter: currencyFormatter,
      text: `${translator("CreditNoteTableAmount")}`,
      sort: true,
    },
    
  ];
  const rowEvents = {
    onClick: (e, row) => {
      e.preventDefault();
      if (e.target.classList.contains("pe-7s-trash")) {
        setDeleteModal({ item: row.id, show: true });
        return;
      }
      history.push(`/update-credit/${row.id}`);
      //history.push(`/add-credit-note`);
    },
  };

  return (
    <CardWrap title={translator("CreditNotesOverview")}>
      <ToolkitProvider keyField="id" data={creditNotes} columns={columns} search>
        {(props) => (
          <>
            <div className="row">
              {roleId < 3 && (
                <div className="col-2">
                  <Select
                    options={companies}
                    placeholder={translator("SelectCompany")}
                    onChange={function (event) {
                      setSearch({ ...search, companyId: event.value });
                      return event;
                    }}
                  />
                </div>
              )}

              <div className="col-2">
                <DatePicker
                  className="form-control"
                  autoComplete="off"
                  valueName="selected"
                  selected={search.from === "" ? null : new Date(search.from)}
                  dateFormat="dd.MM.yyyy"
                  placeholderText={translator("DateFrom")}
                  onChange={(date) =>
                    setSearch({
                      ...search,
                      from: dateHelper.datePickerDateToUtc(date),
                    })
                  }
                />
              </div>
              <div className="col-2">
                <DatePicker
                  className="form-control"
                  autoComplete="off"
                  valueName="selected"
                  selected={search.to === "" ? null : new Date(search.to)}
                  dateFormat="dd.MM.yyyy"
                  placeholderText={translator("DateTo")}
                  onChange={function change(date) {
                    setSearch({
                      ...search,
                      to: dateHelper.datePickerDateToUtc(date),
                    });
                    return date;
                  }}
                />
              </div>
              <div className="col-2">
                <SearchBar
                  {...props.searchProps}
                  placeholder={translator("Search")}
                />
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-2">
                {[superadmin, admin].includes(Number(roleId)) && (
                  <span style={{ float: "right" }}>
                    <Button variant="primary" onClick={handleToAdd}>
                      {isMobile ? (
                        <i className="pe-7s-plus"></i>
                      ) : (
                        translator("CreditNoteAddButton")
                      )}
                    </Button>
                  </span>
                )}
              </div>
            </div>
            

            <hr />
            {isLoading ? (
              <Loader />
            ) : (
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory()}
                noDataIndication={translator("NoDataIndication")}
                rowEvents={rowEvents}
                hover
              />
            )}
          </>
        )}
      </ToolkitProvider>
      <DeleteModal
        show={deleteModal.show}
        handleHide={() =>
          setDeleteModal({
            item: null,
            show: false,
          })
        }
        handleSubmit={handleSubmitDeleteModal}
      />
      <ResultModal
        show={resultModal.show}
        title={resultModal.title}
        text={resultModal.text}
        handleHide={() => setResultModal({ ...resultModal, show: false })}
        handleSubmit={() => {
          setUpdate(new Date());
          setResultModal({ show: false });
        }}
      />
    </CardWrap>
  );
};

export default CreditNoteList;
