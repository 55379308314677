import { BrowserRouter as Router, Switch } from "react-router-dom";
import AuthContextProvider from "./Contexts/AuthContext";
import GlobalContextProvider from "./Contexts/GlobalContext";
import PublicRoute from "./Routes/PublicRoute";
import PrivateRoute from "./Routes/PrivateRoute";
import Layout from "./Components/Layout/Layout";
import Dashboard from "./Components/Dashboard/Dashboard";
import CompanyList from "./Components/Company/Index";
import UserList from "./Components/User/Index";
import Login from "./Components/Auth/Login";
import PublicLayout from "./Components/Layout/PublicLayout";
import InvoiceList from "./Components/Invoice/Index";
import CreditNoteList from "./Components/CreditNote/Index";
import ObligationList from "./Components/Obligation/Index";
import BlackMarketList from "./Components/BlackMarket/Index";
import BlackMarketAdd from "./Components/BlackMarket/BlackMarketAdd";
import BlackMarketUpdate from "./Components/BlackMarket/BlackMarketUpdate";
import UserAdd from "./Components/User/UserAdd";
import UserUpdate from "./Components/User/UserUpdate";
import CompanyAdd from "./Components/Company/CompanyAdd";
import CompanyUpdate from "./Components/Company/CompanyUpdate";
import ObligationAdd from "./Components/Obligation/ObligationAdd";
import ObligationUpdate from "./Components/Obligation/ObligationUpdate";
import CarList from "./Components/Cars/Index";
import CarAdd from "./Components/Cars/CarAdd";
import CarUpdate from "./Components/Cars/CarUpdate";
import GlobalCarList from "./Components/GlobalCars/Index";
import GlobalCarProcessing from "./Components/GlobalCars/Processing";
import GlobalCarEndProcessing from "./Components/GlobalCars/EndProcessing";
import GlobalCarAdd from "./Components/GlobalCars/GlobalCarAdd";
import GlobalCarUpdate from "./Components/GlobalCars/GlobalCarUpdate";
import InvoiceAdd from "./Components/Invoice/InvoiceAdd";
import InvoiceUpdate from "./Components/Invoice/InvoiceUpdate";
import CreditNoteAdd from "./Components/CreditNote/CreditNoteAdd";
import CreditNoteUpdate from "./Components/CreditNote/CreditNoteUpdate";
import DocumentList from "./Components/Documents/Index";
import DocumentAdd from "./Components/Documents/DocumentAdd";
import DocumentUpdate from "./Components/Documents/DocumentUpdate";
function App() {
  return (
    <Router>
      <AuthContextProvider>
        <GlobalContextProvider>
          <Switch>
            <PrivateRoute
              exact
              path="/"
              layout={Layout}
              component={Dashboard}
            />
            <PublicRoute
              exact
              path="/auth"
              layout={PublicLayout}
              component={Login}
            />
            <PrivateRoute
              exact
              path="/companies"
              layout={Layout}
              component={CompanyList}
            />
            <PrivateRoute
              exact
              path="/add-company"
              layout={Layout}
              component={CompanyAdd}
            />
            <PrivateRoute
              exact
              path="/update-company/:id"
              layout={Layout}
              component={CompanyUpdate}
            />
            <PrivateRoute
              exact
              path="/users"
              layout={Layout}
              component={UserList}
            />
            <PrivateRoute
              exact
              path="/add-user"
              layout={Layout}
              component={UserAdd}
            />
            <PrivateRoute
              exact
              path="/update-user/:id"
              layout={Layout}
              component={UserUpdate}
            />
            <PrivateRoute
              exact
              path="/invoices"
              layout={Layout}
              component={InvoiceList}
            />
            <PrivateRoute
              exact
              path="/add-invoice"
              layout={Layout}
              component={InvoiceAdd}
            />
            <PrivateRoute
              exact
              path="/update-invoice/:id"
              layout={Layout}
              component={InvoiceUpdate}
            />
            <PrivateRoute
              exact
              path="/credit-notes"
              layout={Layout}
              component={CreditNoteList}
            />
            <PrivateRoute
              exact
              path="/add-credit-note"
              layout={Layout}
              component={CreditNoteAdd}
            />
            <PrivateRoute
              exact
              path="/update-credit/:id"
              layout={Layout}
              component={CreditNoteUpdate}
            />
             <PrivateRoute
              exact
              path="/update-credit-note/:id"
              layout={Layout}
              component={CreditNoteUpdate}
            />
            <PrivateRoute
              exact
              path="/obligations"
              layout={Layout}
              component={ObligationList}
            />
            <PrivateRoute
              exact
              path="/add-obligation"
              layout={Layout}
              component={ObligationAdd}
            />
            <PrivateRoute
              exact
              path="/update-obligation/:id"
              layout={Layout}
              component={ObligationUpdate}
            />
            <PrivateRoute
              exact
              path="/blackMarkets"
              layout={Layout}
              component={BlackMarketList}
            />
            <PrivateRoute
              exact
              path="/add-blackmarket"
              layout={Layout}
              component={BlackMarketAdd}
            />
            <PrivateRoute
              exact
              path="/update-blackmarket/:id"
              layout={Layout}
              component={BlackMarketUpdate}
            />
            <PrivateRoute
              exact
              path="/cars"
              layout={Layout}
              component={CarList}
            />
            <PrivateRoute
              exact
              path="/add-car"
              layout={Layout}
              component={CarAdd}
            />
            <PrivateRoute
              exact
              path="/update-car/:id"
              layout={Layout}
              component={CarUpdate}
            />
            <PrivateRoute
              exact
              path="/global-cars"
              layout={Layout}
              component={GlobalCarList}
            />
            <PrivateRoute
              exact
              path="/global-cars-processing"
              layout={Layout}
              component={GlobalCarProcessing}
            />
            <PrivateRoute
              exact
              path="/global-cars-endprocessing"
              layout={Layout}
              component={GlobalCarEndProcessing}
            />
            <PrivateRoute
              exact
              path="/add-global-car"
              layout={Layout}
              component={GlobalCarAdd}
            />
            <PrivateRoute
              exact
              path="/update-global-car/:id"
              layout={Layout}
              component={GlobalCarUpdate}
            />
            <PrivateRoute
              exact
              path="/documents"
              layout={Layout}
              component={DocumentList}
            />
            <PrivateRoute
              exact
              path="/add-document"
              layout={Layout}
              component={DocumentAdd}
            />
            <PrivateRoute
              exact
              path="/update-document/:id"
              layout={Layout}
              component={DocumentUpdate}
            />
          </Switch>
        </GlobalContextProvider>
      </AuthContextProvider>
    </Router>
  );
}

export default App;
