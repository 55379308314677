import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import CardWrap from "../Common/CardWrap";
import ErrorMsg from "../Common/ErrorMsg";
import Select from "react-select";
import { companyService } from "../../Services/CompanyService";
import { carService } from "../../Services/CarService";
import { carImageService } from "../../Services/CarImageService";
import { carDocumentService } from "../../Services/CarDocumentService";
import Loader from "../Common/Loader";
import { useTranslation } from "react-i18next";
import DztImageGalleryComponent from "reactjs-image-gallery";
import { staticUrl } from "../../Config/Url";
import ResultModal from "../Common/ResultModal";
import { AuthContext } from "../../Contexts/AuthContext";
import { roles } from "../../Config/Roles";
import ImageModal from "./ImageModal";
import NewInvoiceModal from "./NewInvoiceModal";
import DocumentModal from "./DocumentModal";
import { invoiceService } from "../../Services/InvoiceService";
import BootstrapTable from "react-bootstrap-table-next";

import ToolkitProvider from "react-bootstrap-table2-toolkit";

const CarUpdate = ({ match }) => {
  const { t: translator } = useTranslation();
  const history = useHistory();
  const [companies, setCompanies] = useState([]);
  const { register, handleSubmit, errors, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const [data, setData] = useState(null);
  const [images, setImages] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [gallery, setGallery] = useState([]);
  const { roleId } = useContext(AuthContext);
  const { superadmin, admin } = roles;
  const [update, setUpdate] = useState(new Date());

  const [show, setShow] = useState(false);
  const [resultModal, setResultModal] = useState({
    show: false,
    title: "",
    text: "",
  });
  const [imageModal, setImageModal] = useState(false);
  const [documentModal, setDocumentModal] = useState(false);

  const handleModalSubmit = (submitData) => {
    console.log("data", submitData);
    invoiceService
      .addExternalInvoice(submitData, data.id)
      .then((res) =>
        res.data.success
          ? setResultModal({
              show: true,
              title: "Success",
              text: "SuccessText",
            })
          : setResultModal({
              show: true,
              title: "Error",
              text: "ErrorText",
            })
      )
      .catch((err) => console.log(err));
  };
  const options = [
    // { value: 1, label: "Superadmin" },
    { value: 1, label: "Location1" },
    { value: 2, label: "Location2" },
  ];
  const handleHide = () => {
    setShow(false);
  };
  useEffect(() => {
    Promise.all([
      carService.getById(match.params.id),
      companyService.getCompanies(),
      carImageService.getCarImages(match.params.id),
      carDocumentService.getCarDocuments(match.params.id),
    ])
      .then((res) => {
        console.log("car", res);
        setData(res[0].data.data);
        setCompanies(
          res[1].data.data.map((i) => ({ value: i.id, label: i.name }))
        );
        // console.log(res[2].data.data);
        setGallery(
          res[2].data.data.map((i) => ({
            url: `${staticUrl}/images/${i.name}`,
            title: i.name,
            thumbUrl: `${staticUrl}/images/${i.name}`,
          }))
        );
        setImages(res[2].data.data);
        setDocuments(res[3].data.data);
      })
      .catch((err) => console.log(err));
  }, [match.params.id, update]);

  const onSubmit = (data) => {
    carService
      .updateCar(match.params.id, data)
      .then((res) =>
        res.data.success
          ? setResultModal({
              show: true,
              title: "Success",
              text: "SuccessText",
            })
          : setResultModal({
              show: true,
              title: "Error",
              text: "ErrorText",
            })
      )
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      dataField: "id",
      text: "",
      formatter: actionFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "50px", textAlign: "center" };
      },
    },
    {
      dataField: "name",
      formatter: urlFormatter,
      text: `${translator("Document")}`,
    },
  ];

  function urlFormatter(cell, row) {
    return (
      <a href={`${staticUrl}/car-documents/${cell}`} target="_blank">
        {cell}
      </a>
    );
  }
  function actionFormatter(cell, row) {
    return (
      <div className="action">
        <i
          className="pe-7s-trash"
          style={{ color: "#c00", fontWeight: "bold", fontSize: 15 }}
        ></i>
      </div>
    );
  }
  const rowEvents = {
    onClick: (e, row) => {
      e.preventDefault();
      if (e.target.classList.contains("pe-7s-trash")) {
        carDocumentService
          .deleteCarDocument(row.id)
          .then((res) => {
            res.data.success
              ? setDocuments(documents.filter((e) => e.id !== row.id))
              : //alert("ok")
                alert("Not ok");
          })
          .catch((err) => console.log(err));
        return;
      }
    },
  };

  return (
    <CardWrap title={translator("CarUpdateHeader")}>
      {companies.length === 0 || data === null ? (
        <Loader />
      ) : (
        <>
          {!data.invoiceId && (
            <div>
              Kreiranje računa iz vozila
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => setShow(true)}
              >
                {translator("NewItem")}
              </button>
            </div>
          )}

          <form onSubmit={handleSubmit(onSubmit)} className="row">
            <div className="col-lg-4">
              <div className="form-group">
                <label>{translator("CarFormName")}</label>
                <input
                  name="name"
                  className="form-control"
                  type="text"
                  ref={register({ required: true })}
                  defaultValue={data.name}
                />
                {errors.name && <ErrorMsg text={translator("RequiredField")} />}
              </div>
              <div className="form-group">
                <label>{translator("CarFormRegNum")}</label>
                <input
                  name="regNum"
                  className="form-control"
                  type="text"
                  ref={register({ required: true })}
                  defaultValue={data.regNum}
                />
                {errors.regNum && (
                  <ErrorMsg text={translator("RequiredField")} />
                )}
              </div>
              <div className="form-group">
                <label>{translator("CarFormChasisNum")}</label>
                <input
                  name="chasisNum"
                  className="form-control"
                  type="text"
                  ref={register({ required: true })}
                  defaultValue={data.chasisNum}
                />
                {errors.chasisNum && (
                  <ErrorMsg text={translator("RequiredField")} />
                )}
              </div>
              <div className="form-group">
                <label>{translator("CarFormEngineNum")}</label>
                <input
                  name="engineNum"
                  className="form-control"
                  type="text"
                  ref={register({ required: true })}
                  defaultValue={data.engineNum}
                />
                {errors.engineNum && (
                  <ErrorMsg text={translator("RequiredField")} />
                )}
              </div>
              <div className="form-group">
                <label>{translator("CarFormEngineSize")}</label>
                <input
                  name="engineSize"
                  className="form-control"
                  type="text"
                  ref={register({ required: true })}
                  defaultValue={data.engineSize}
                />
                {errors.engineSize && (
                  <ErrorMsg text={translator("RequiredField")} />
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <label>{translator("CarFormColor")}</label>
                <input
                  className="form-control"
                  type="text"
                  name="color"
                  ref={register({ required: true })}
                  defaultValue={data.color}
                />
                {errors.color && (
                  <ErrorMsg text={translator("RequiredField")} />
                )}
              </div>

              <div className="form-group">
                <label>{translator("CarFormKw")}</label>
                <input
                  className="form-control"
                  type="text"
                  name="kw"
                  ref={register({ required: true })}
                  defaultValue={data.kw}
                />
                {errors.kw && <ErrorMsg text={translator("RequiredField")} />}
              </div>
              <div className="form-group">
                <label>{translator("InvoiceFormSubCustomer")}</label>
                <input
                  type="text"
                  name="subCustomer"
                  ref={register({ required: false })}
                  className="form-control"
                  defaultValue={data.subCustomer}
                />
              </div>
              <div className="form-group">
                <label>{translator("CarFormLotNumber")}</label>
                <input
                  type="text"
                  name="lotNumber"
                  ref={register({ required: false })}
                  className="form-control"
                  defaultValue={data.lotNumber}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <label>{translator("CarFormImages")}</label>
                <input
                  className="form-control"
                  type="file"
                  name="images"
                  ref={register()}
                  multiple
                />
              </div>
              <div className="form-group">
                <input
                  type="checkbox"
                  name="isPaid"
                  ref={register}
                  defaultChecked={data.isPaid}
                />

                <label>{translator("InvoiceFormPaid")}</label>
              </div>
              <div className="form-group">
                <input
                  type="checkbox"
                  name="isExported"
                  ref={register}
                  defaultChecked={data.isExported}
                />
                <label>{translator("InvoiceFormExported")}</label>
              </div>
              <div className="form-group">
                <input
                  type="checkbox"
                  name="isArrived"
                  ref={register}
                  defaultChecked={data.isArrived}
                />
                <label>{translator("InvoiceFormArrived")}</label>
              </div>
              <div className="form-group">
              <label>{translator("CarLocation")}</label>
              <Controller
                as={Select}
                control={control}
                name="locationId"
                rules={{ required: true }}
                placeholder={translator("LocationDropdownOption")}
                options={options}
                defaultValue={{
                  label:
                  options.length && data.locationId !== null
                      ? options.filter(
                          (x) => x.value === data.locationId
                        )[0].label
                      : "",
                  value: data.locationId,
                }}
              />
              {errors.locationId && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
            </div>
            <div
              className="container-fluid"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={() => history.goBack()}
              >
                {translator("ButtonCancel")}
              </button>
              &nbsp;
              {[superadmin, admin].includes(Number(roleId)) ? (
                <button className="btn btn-primary" type="submit">
                  {translator("ButtonSave")}
                </button>
              ) : null}
            </div>
          </form>

          <div className="row">
            {gallery.length === 0 || data === null ? (
              ""
            ) : (
              <>
                <div className="col-md-12">
                  <h5>{translator("CarGallery")}</h5>
                  {[superadmin, admin].includes(Number(roleId)) && (
                    <button
                      className="btn btn-primary"
                      onClick={() => setImageModal(true)}
                    >
                      {translator("CarImageManage")}
                    </button>
                  )}

                  <DztImageGalleryComponent images={gallery} />
                </div>
              </>
            )}
          </div>
          <div className="row">
            {data === null ? (
              ""
            ) : (
              <>
                <div className="col-md-12">
                  <h5>{translator("CarGallery")}</h5>
                  {[superadmin, admin].includes(Number(roleId)) && (
                    <button
                      className="btn btn-primary"
                      onClick={() => setDocumentModal(true)}
                    >
                      {translator("CarDocumentManage")}
                    </button>
                  )}

                  <ToolkitProvider
                    keyField="id"
                    data={documents}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <>
                        <BootstrapTable
                          {...props.baseProps}
                          //pagination={paginationFactory()}
                          noDataIndication={translator("NoDataIndication")}
                          rowEvents={rowEvents}
                          hover
                        />
                      </>
                    )}
                  </ToolkitProvider>
                </div>
              </>
            )}
          </div>
        </>
      )}

      {images.length > 0 && (
        <ImageModal
          images={images}
          show={imageModal}
          translator={translator}
          handleHide={() => {
            setImageModal(false);
            setUpdate(new Date());
          }}
        />
      )}
      <DocumentModal
        carId={match.params.id}
        show={documentModal}
        translator={translator}
        handleHide={() => {
          setDocumentModal(false);
          setUpdate(new Date());
        }}
      />
      <NewInvoiceModal
        show={show}
        handleHide={handleHide}
        handleModalSubmit={handleModalSubmit}
        translator={translator}
      />
      <ResultModal
        show={resultModal.show}
        title={resultModal.title}
        text={resultModal.text}
        handleHide={() => setResultModal({ ...resultModal, show: false })}
        handleSubmit={() => history.push("/cars")}
      />
    </CardWrap>
  );
};

export default CarUpdate;
