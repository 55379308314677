import React, { useEffect, useState } from "react";
import CardWrap from "../Common/CardWrap";
import { useForm, Controller } from "react-hook-form";
import ErrorMsg from "../Common/ErrorMsg";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { companyService } from "../../Services/CompanyService";
import { creditNoteService } from "../../Services/CreditNoteService";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory from "react-bootstrap-table2-editor";
import NewItemModal from "./NewItemModal";
import ResultModal from "../Common/ResultModal";
import { convertToPdf } from "../../Services/PDFService/invoiceToPdf.service";

const CreditNoteAdd = () => {
  const { t: translator } = useTranslation();
  const history = useHistory();
  const [companies, setCompanies] = useState([]);
  const { register, handleSubmit, errors, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [CreditNoteNum, setCreditNoteNum] = useState(null);
  const [resultModal, setResultModal] = useState({
    show: false,
    title: "",
    text: "",
  });
  const [disabled, setDisabled] = useState(false);

  const handleModalSubmit = (data) => {
    //console.log("data", data);
    setData((prevData) => [...prevData, data]);
    setShow(false);
  };

  const handleHide = () => {
    setShow(false);
  };

  useEffect(() => {
    creditNoteService
      .getCreditNoteNumber()
      .then((res) => {
        console.log(res.data);
        setCreditNoteNum(res.data);
      })
      .catch((err) => console.log(err.response));
    companyService
      .getCompanies()
      .then((res) =>
        setCompanies(res.data.data.map((i) => ({ value: i.id, label: i.name })))
      )
      .catch((err) => console.log(err.response));
  }, []);

  const onSubmit = (submitData) => {
    setDisabled(true);
    creditNoteService
      .addCreditNote(submitData, data)
      .then((res) =>
        res.data.success
          ? res.data.data
            ? exportToPdf(res.data.data, res.data.items, true)
            : setResultModal({
                show: true,
                title: "Success",
                text: "SuccessText",
              })
          : setResultModal({
              show: true,
              title: "Error",
              text: "ErrorText",
            })
      )
      .catch((err) => console.log(err));
  };

  function actionFormatter(cell, row) {
    return (
      <div className="action">
        <i
          className="pe-7s-trash"
          style={{ color: "#c00", fontWeight: "bold", fontSize: 15 }}
        ></i>
      </div>
    );
  }

  const columns = [
    {
      dataField: "comodityCode",
      text: "",
      formatter: actionFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "50px", textAlign: "center" };
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setData(data.filter((i) => i.comodityCode !== row.comodityCode));
        },
      },
    },
    {
      dataField: "comodityCode",
      text: `${translator("ComodityCode")}`,
    },
    {
      dataField: "name",
      text: `${translator("Name")}`,
    },
    {
      dataField: "regNum",
      text: `${translator("RegNum")}`,
    },
    {
      dataField: "chasisNum",
      text: `${translator("ChasisNum")}`,
    },
    {
      dataField: "engineNum",
      text: `${translator("EngineNum")}`,
    },
    {
      dataField: "engineSize",
      text: `${translator("EngineSize")}`,
    },
    {
      dataField: "kw",
      text: `${translator("kW")}`,
    },
    {
      dataField: "color",
      text: `${translator("Color")}`,
    },
    {
      dataField: "amount",
      text: `${translator("Amount")}`,
    },
    {
      dataField: "quantity",
      text: `${translator("Quantity")}`,
    },
  ];

  const columns2 = [
    {
      dataField: "comodityCode",
      text: "",
      formatter: actionFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "50px", textAlign: "center" };
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setData(data.filter((i) => i.comodityCode !== row.comodityCode));
        },
      },
    },
    {
      dataField: "comodityCode",
      text: `${translator("ComodityCode")}`,
    },
    {
      dataField: "partNumber",
      text: `${translator("PartNumber")}`,
    },
    {
      dataField: "description",
      text: `${translator("Description")}`,
    },
    {
      dataField: "referenceNumber",
      text: `${translator("ReferenceNumber")}`,
    },
    {
      dataField: "amount",
      text: `${translator("Amount")}`,
    },
    {
      dataField: "quantity",
      text: `${translator("Quantity")}`,
    },
  ];

  const exportToPdf = (data, items, sendMail) => {
    //let base64QR = document.getElementById("qrCode").toDataURL();
    convertToPdf.handlePrint(data, items, sendMail, translator);
    setResultModal({
      show: true,
      title: "Success",
      text: "SuccessText",
    });
  };

  return (
    <CardWrap title={translator("CreditNoteAddHeader")}>
      <form onSubmit={handleSubmit(onSubmit)} className="row">
        <div className="col-lg-3">
          <div className="form-group">
            <label>{translator("CreditNoteFormNumber")}</label>
            {CreditNoteNum && (
              <input
                type="text"
                name="CreditNoteNumber"
                ref={register({ required: true })}
                className="form-control"
                defaultValue={CreditNoteNum}
              />
            )}

            {errors.CreditNoteNumber && (
              <ErrorMsg text={translator("RequiredField")} />
            )}
          </div>
          <div className="form-group">
            <label>{translator("CreditNoteFormDate")}</label>
            <Controller
              as={DatePicker}
              control={control}
              className="form-control"
              valueName="selected"
              autocomplete="off"
              dateFormat="dd.MM.yyyy"
              name="creditNoteDate"
              rules={{ required: true }}
            />
            {errors.creditNoteDate && (
              <ErrorMsg text={translator("RequiredField")} />
            )}
          </div>
          <div className="form-group">
            <label>{translator("CreditNoteFormCompany")}</label>
            <Controller
              as={Select}
              control={control}
              options={companies}
              rules={{ required: false }}
              name="companyId"
              placeholder={translator("CompanyDropdownOption")}
            />
            {errors.companyId && (
              <ErrorMsg text={translator("RequiredField")} />
            )}
          </div>
          <div className="form-group">
            <label>{translator("CreditNoteFormCustomerName")}</label>
            <input
              type="text"
              name="companyName"
              ref={register()}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>{translator("CreditNoteFormCustomerAddress")}</label>
            <input
              type="text"
              name="companyAddress"
              ref={register()}
              className="form-control"
            />
          </div>
          
        </div>
        <div className="col-lg-9 border-left">
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => setShow(true)}
          >
            {translator("NewItem")}
          </button>
          <hr />
          <BootstrapTable
            keyField="comodityCode"
            data={data.filter((a) => a.isCar === true)}
            columns={columns}
            pagination={paginationFactory()}
            cellEdit={cellEditFactory({ mode: "click", blurToSave: true })}
            noDataIndication={translator("NoDataIndication")}
          />
          <hr />
          <BootstrapTable
            keyField="comodityCode"
            data={data.filter((a) => a.isCar === false)}
            columns={columns2}
            pagination={paginationFactory()}
            cellEdit={cellEditFactory({ mode: "click", blurToSave: true })}
            noDataIndication={translator("NoDataIndication")}
          />
        </div>
        <div
          className="container-fluid"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={() => history.goBack()}
          >
            {translator("ButtonCancel")}
          </button>
          &nbsp;
          <button className="btn btn-primary" type="submit" disabled={disabled}>
            {translator("ButtonSave")}
          </button>
        </div>
      </form>
      <NewItemModal
        show={show}
        handleHide={handleHide}
        handleModalSubmit={handleModalSubmit}
        translator={translator}
      />
      <ResultModal
        show={resultModal.show}
        title={resultModal.title}
        text={resultModal.text}
        handleHide={() => setResultModal({ ...resultModal, show: false })}
        handleSubmit={() => history.push("/credit-notes")}
      />
    </CardWrap>
  );
};

export default CreditNoteAdd;
