import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import "../../Resources/Styles/Sidebar.css";
import { GlobalContext } from "../../Contexts/GlobalContext";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../Contexts/AuthContext";
import { roles } from "../../Config/Roles";

const Sidebar = () => {
  const { isActiveMenu, isMobile, toggleMenu } = useContext(GlobalContext);
  const { roleId } = useContext(AuthContext);
  const { superadmin, admin, company } = roles;
  const { t: translator } = useTranslation();

  const mobileMenuClick = () => {
    if (isMobile) toggleMenu();
  };

  const handleAdministrationClick = () => {
    if (
      document.getElementById("submenuInvoicesIssuedReport") &&
      document
        .getElementById("submenuInvoicesIssuedReport")
        .classList.contains("show")
    ) {
      document.getElementById("reports").click();
    }
    if (
      document.getElementById("submenuAccounting") &&
      document.getElementById("submenuAccounting").classList.contains("show")
    ) {
      document.getElementById("accounting").click();
    }
  };
  const handleGlobalCarsClick = () => {
    if (
      document.getElementById("globalCars") &&
      document.getElementById("globalCars").classList.contains("show")
    ) {
      document.getElementById("globalCars").click();
    }
  };
  const handleReportClick = () => {
    console.log("Nema ništa");
  };

  return (
    <nav id="sidebar" className={isActiveMenu ? "" : "active"}>
      <ul className="list-unstyled components my-first-step">
        {[superadmin, admin, company].includes(Number(roleId)) && (
          <li className="">
            <NavLink
              className="menu-item"
              exact
              to="/"
              onClick={mobileMenuClick}
            >
              <i
                className="pe-7s-home"
                style={{ fontWeight: "600", fontSize: 25 }}
              ></i>
              &nbsp; {translator("MenuHome")}
            </NavLink>
          </li>
        )}
        {[superadmin, admin].includes(Number(roleId)) && (
          <li className="">
            <NavLink
              className="menu-item"
              to="/companies"
              onClick={mobileMenuClick}
            >
              <i
                className="pe-7s-portfolio"
                style={{ fontWeight: "600", fontSize: 25 }}
              ></i>
              &nbsp; {translator("MenuCompany")}
            </NavLink>
          </li>
        )}
        {[superadmin, admin].includes(Number(roleId)) && (
          <li className="">
            <NavLink
              className="menu-item"
              to="/users"
              onClick={mobileMenuClick}
            >
              <i
                className="pe-7s-users"
                style={{ fontWeight: "600", fontSize: 25 }}
              ></i>
              &nbsp; {translator("MenuUsers")}
            </NavLink>
          </li>
        )}
        <li className="">
          <NavLink
            className="menu-item"
            to="/invoices"
            onClick={mobileMenuClick}
          >
            <i
              className="pe-7s-note2"
              style={{ fontWeight: "600", fontSize: 25 }}
            ></i>
            &nbsp; {translator("MenuInvoices")}
          </NavLink>
        </li>
        <li className="">
          <NavLink
            className="menu-item"
            to="/credit-notes"
            onClick={mobileMenuClick}
          >
            <i
              className="pe-7s-note"
              style={{ fontWeight: "600", fontSize: 25 }}
            ></i>
            &nbsp; {translator("MenuCreditNotes")}
          </NavLink>
        </li>
        <li className="">
          <NavLink className="menu-item" to="/cars" onClick={mobileMenuClick}>
            <i
              className="pe-7s-car"
              style={{ fontWeight: "600", fontSize: 25 }}
            ></i>
            &nbsp; {translator("MenuCars")}
          </NavLink>
        </li>
        {[superadmin].includes(Number(roleId)) && (
          <li>
            <a
              href="#pageSubmenu11"
              data-toggle="collapse"
              aria-expanded="false"
              className="dropdown-toggle collapsed"
              id="globalCars"
              onClick={handleGlobalCarsClick}
            >
              <i
                className="pe-7s-tools"
                style={{ fontWeight: "600", fontSize: 25 }}
              ></i>
              &nbsp; {translator("MenuGlobalCars")}
            </a>
            <ul className="collapse list-unstyled" id="pageSubmenu11">
              <li style={{ paddingLeft: 30 }}>
                <NavLink
                  className="menu-item"
                  to="/global-cars"
                  onClick={mobileMenuClick}
                >
                  &nbsp; {translator("MenuGlobalCarsAll")}
                </NavLink>
              </li>
              <li style={{ paddingLeft: 30 }}>
                <NavLink
                  className="menu-item"
                  to="/global-cars-processing"
                  onClick={mobileMenuClick}
                >
                  &nbsp; {translator("MenuGlobalCarsInProcessing")}
                </NavLink>
              </li>
              <li style={{ paddingLeft: 30 }}>
                <NavLink
                  className="menu-item"
                  to="/global-cars-endprocessing"
                  onClick={mobileMenuClick}
                >
                  &nbsp; {translator("MenuGlobalCarsEndProcessing")}
                </NavLink>
              </li>
            </ul>
          </li>
        )}

        <li className="">
          <NavLink
            className="menu-item"
            to="/documents"
            onClick={mobileMenuClick}
          >
            <i
              className="pe-7s-file"
              style={{ fontWeight: "600", fontSize: 25 }}
            ></i>
            &nbsp; {translator("MenuDocuments")}
          </NavLink>
        </li>

        {[superadmin].includes(Number(roleId)) && (
          <li>
            <a
              href="#pageSubmenu1"
              data-toggle="collapse"
              aria-expanded="false"
              className="dropdown-toggle collapsed"
              id="administration2"
              onClick={handleReportClick}
            >
              <i
                className="pe-7s-news-paper"
                style={{ fontWeight: "600", fontSize: 25 }}
              ></i>
              &nbsp; {translator("MenuReports")}
            </a>
          </li>
        )}
        {[superadmin].includes(Number(roleId)) && (
          <li>
            <a
              href="#pageSubmenu9"
              data-toggle="collapse"
              aria-expanded="false"
              className="dropdown-toggle collapsed"
              id="administration"
              onClick={handleAdministrationClick}
            >
              <i
                className="pe-7s-tools"
                style={{ fontWeight: "600", fontSize: 25 }}
              ></i>
              &nbsp; {translator("MenuAdministration")}
            </a>
            <ul className="collapse list-unstyled" id="pageSubmenu9">
              <li style={{ paddingLeft: 30 }}>
                <NavLink
                  className="administrationItems"
                  to="/obligations"
                  onClick={mobileMenuClick}
                >
                  {translator("MenuObligations")}
                </NavLink>
              </li>
              <li style={{ paddingLeft: 30 }}>
                <NavLink
                  className="administrationItems"
                  to="/blackMarkets"
                  onClick={mobileMenuClick}
                >
                  {translator("MenuBlackMarket")}
                </NavLink>
              </li>
            </ul>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Sidebar;
