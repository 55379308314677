import { creditNoteUrl } from "../Config/Url";
import axios from "axios";

export const creditNoteService = {
  getCreditNotes,
  deleteCreditNote,
  addCreditNote,
  addCreditNoteFromInvoice,
  getCreditNote,
  updateCreditNote,
  sendMail,
  getCreditNoteNumber,
  addExternalCreditNote,
};

async function getCreditNotes(
  companyId,
  from,
  to
) {
  //var url=
 
  return axios
    .get(
      `${creditNoteUrl}?companyId=${companyId}&from=${from}&to=${to}`
    )
    .then((res) => res)
    .catch((err) => err.response);
}

async function deleteCreditNote(id) {
  return axios
    .delete(`${creditNoteUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function addCreditNote(submitData, data) {
  console.log(submitData);

  //return;
  return axios
    .post(creditNoteUrl, {
      creditNoteNumber: submitData.creditNoteNumber,
      creditNoteDate: new Date(submitData.creditNoteDate),
      companyId: submitData.companyId.value,         
      userId: Number(localStorage.getItem("userId")),     
      companyAddress: submitData.companyAddress,
      companyName: submitData.companyName,
      totalAmount: data.reduce(
        (sum, { amount, quantity }) => sum + amount * quantity,
        0
      ),     
      invoiceId: null,
      data,
    })
    .then((res) => res)
    .catch((err) => err.response);
}
async function addCreditNoteFromInvoice(submitData, data) {
  console.log(submitData);

  //return;
  return axios
    .post(creditNoteUrl, {
      creditNoteNumber: submitData.creditNoteNumber,
      creditNoteDate: new Date(submitData.creditNoteDate),
      companyId: submitData.companyId,
      userId: Number(localStorage.getItem("userId")),  
      companyAddress: submitData.companyAddress,
      companyName: submitData.companyName,
      paymentType:submitData.paymentType ?? "",
      totalAmount: data.reduce(
        (sum, { amount, quantity }) => sum + amount * quantity,
        0
      ),     
      invoiceId:submitData.invoiceId?? null,
      data,
    })
    .then((res) => res)
    .catch((err) => err.response);
}

async function addExternalCreditNote(data, carId) {
  return axios
    .post(`${creditNoteUrl}/external`, {
      creditNoteNumber: data.creditNoteNumber,
      creditNoteDate: new Date(data.creditNoteDate),
      amount: data.amount,
      quantity: data.quantity,
      paymentType: data.paymentType.value,
      carId: carId,
    })
    .then((res) => res)
    .catch((err) => err.response);
}
async function getCreditNote(id) {
  console.log("setLink");
  return axios
    .get(`${creditNoteUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function updateCreditNote(id, submitData, items) {
  console.log("i", submitData);
  return axios
    .put(`${creditNoteUrl}/${id}`, {
      creditNoteNumber: submitData.creditNoteNumber,
      creditNoteDate: new Date(submitData.creditNoteDate),
      subCustomer: submitData.subCustomer,
      isPaid: submitData.isPaid,
      isArrived: submitData.isArrived,
      isExported: submitData.isExported,
      paymentType: submitData.paymentType.value,
      totalAmount: 0,
      items: items,
    })
    .then((res) => res)
    .catch((err) => err.response);
}

async function sendMail(id, encodedDocument) {
  return axios
    .put(`${creditNoteUrl}/${id}/sendMail`, {
      FileEncoded: encodedDocument,
    })
    .then((res) => res)
    .catch((err) => err.response);
}

async function getCreditNoteNumber(id) {
  return axios
    .get(`${creditNoteUrl}/getCreditNoteNumber`)
    .then((res) => res)
    .catch((err) => err.response);
}
