export const apiUrl = `http://global-autosalvage.com:5002/api`;
export const staticUrl = `http://global-autosalvage.com:5002/Files/`;

//export const apiUrl = `http://localhost:5002/api`;
//export const staticUrl = `http://localhost:5002/Files`;

export const todoUrl = `${apiUrl}/todo`;
export const companyUrl = `${apiUrl}/company`;
export const userUrl = `${apiUrl}/user`;
export const authUrl = `${apiUrl}/auth`;
export const carUrl = `${apiUrl}/car`;
export const globalCarUrl = `${apiUrl}/globalcar`;
export const creditNoteUrl = `${apiUrl}/creditNote`;
export const invoiceUrl = `${apiUrl}/invoice`;
export const transactionUrl = `${apiUrl}/obligationTransactions`;
export const obligationUrl = `${apiUrl}/obligation`;
export const resourceUrl = `${apiUrl}/resources`;
export const langUrl = `${apiUrl}/language`;
export const blackMarketUrl = `${apiUrl}/blackMarket`;
export const carImageUrl = `${apiUrl}/carImage`;
export const carDocumentUrl = `${apiUrl}/cardocument`;
export const globalCarImageUrl = `${apiUrl}/globalCarImage`;
export const documentUrl = `${apiUrl}/document`;
export const documentAttUrl = `${apiUrl}/documentAttachment`;
export const reportUrl = `${apiUrl}/report`;
export const dashboardUrl = `${apiUrl}/dashboard`;
