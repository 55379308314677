import { logo } from "../../Config/Image";
import { invoiceService } from "../InvoiceService";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const convertToPdf = {
  handlePrint,
};

async function handlePrint(
  invoiceDetails,
  invoiceDetailsItems,
  sendMail,
  translator
) {
  let dataArray = [];
  var dataRowHeader = [];
  console.log(invoiceDetailsItems);

  dataRowHeader.push(
    {
      text: `${translator("InvoicePrintDescription")}`,
      fontSize: 10,
      alignment: "left",
      bold: true,
    },
    {
      text: `${translator("InvoicePrintPrice")}`,
      fontSize: 10,
      alignment: "right",
      bold: true,
    },
    {
      text: `${translator("InvoicePrintQty")}`,
      fontSize: 10,
      alignment: "right",
      bold: true,
    },

    {
      text: `${translator("InvoicePrintTotalRow")}`,
      fontSize: 10,
      alignment: "right",
      bold: true,
    }
  );

  //items
  dataArray.push(dataRowHeader);

  invoiceDetailsItems.forEach(function (sourceRow) {
    var dataRow = [];
    if (sourceRow.isCar === true) {
      let text = "";
      if (sourceRow.name != " ") {
        text += `Name : ${sourceRow.name}\n`;
      }
      if (sourceRow.regNum != "") {
        console.log("REG");
        text += `Registration Number : ${sourceRow.regNum}\n`;
      }
      if (sourceRow.chasisNum != "") {
        text += `Chassis Num : ${sourceRow.chasisNum}\n`;
      }
      if (sourceRow.engineNum != "") {
        text += `Engine Num  : ${sourceRow.engineNum}\n`;
      }
      if (sourceRow.engineSize != "") {
        text += `Engine Size : ${sourceRow.engineSize}\n`;
      }
      if (sourceRow.kw != "") {
        text += `KW : ${sourceRow.kw}\n`;
      }
      if (sourceRow.color != "") {
        text += `Colour : ${sourceRow.color}\n`;
      }
      if (sourceRow.comodityCode != "") {
        text += `Commodity Code : ${sourceRow.comodityCode}\n`;
      }

      dataRow.push({
        text: text,
        fontSize: 10,
        alignment: "left",
      });
    } else {
      dataRow.push({
        text: `Part Number : ${sourceRow.partNumber}\nReference Number : ${sourceRow.referenceNumber}\nCommodity Code : ${sourceRow.comodityCode}`,
        fontSize: 10,
        alignment: "left",
      });
    }

    dataRow.push({
      text: new Intl.NumberFormat("en", {
        style: "currency",
        currency: "GBP",
      }).format(Number(sourceRow.amount)),
      fontSize: 10,
      alignment: "right",
    });

    dataRow.push({
      text: `${sourceRow.quantity}`,
      fontSize: 12,
      alignment: "right",
    });

    dataRow.push({
      text: new Intl.NumberFormat("en", {
        style: "currency",
        currency: "GBP",
      }).format(Number(sourceRow.quantity) * Number(sourceRow.amount)),
      fontSize: 10,
      alignment: "right",
    });

    dataArray.push(dataRow);

    //dataArray.push(dataRow);
  });

  let dd = {
    info: {
      title: "Invoice",
      author: "GLOBAL AUTO SALVAGE",
      subject: "Invoice print",
    },
    // a string or { width: number, height: number }
    pageSize: "A4",
    // by default we use portrait, you can change it to landscape if you wish
    pageOrientation: "portrait",
    // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
    pageMargins: [20, 250, 15, 20],
    header: {
      columns: [
        {
          image: logo,
          width: 600,
        },
      ],
    },

    content: [
      {
        columns: [
          {
            text: [
              {
                text: `${translator("InvoicePrintInvoiceNum")}: ${
                  invoiceDetails.invoiceNumber
                }\n\n`,
                fontSize: 15,
                bold: true,
                alignment: "left",
                letterSpacing: 10,
                lineHeight: 0.5,
                marginTop: 30,
              },
              {
                text: `Rep: ${invoiceDetails.username}\n\n`,
                fontSize: 12,
                bold: true,
                alignment: "left",
                letterSpacing: 10,
                lineHeight: 0.5,
                marginTop: 20,
              },
              {
                text: `${translator(
                  "InvoicePrintDate"
                )}: ${new Intl.DateTimeFormat("hr-HR", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  timeZone: "Europe/Zagreb",
                }).format(new Date(invoiceDetails.invoiceDate))}\n\n`,
                fontSize: 12,
                bold: true,
                alignment: "left",
                letterSpacing: 10,
                lineHeight: 0.5,
                marginTop: 20,
              },
            ],
            fontSize: 12,
            marginRight: 20,
            marginTop: 25,
            bold: false,
            alignment: "left",
            color: "#333",
            height: 60,
            lineHeight: 0.7,
            letterSpacing: 50,
          },
          {
            text: [
              {
                text: `${translator("InvoicePrintCompany")}: \n\n`,
                fontSize: 15,
                bold: true,
                alignment: "left",
                letterSpacing: 10,
                lineHeight: 0.5,
                marginTop: 30,
              },
              {
                text: `${invoiceDetails.companyName}\n\n`,
                fontSize: 12,
                bold: true,
                alignment: "left",
                letterSpacing: 10,
                lineHeight: 0.5,
                marginTop: 20,
              },
              {
                text: `${invoiceDetails.companyAddress}\n\n`,
                fontSize: 12,
                bold: true,
                alignment: "left",
                letterSpacing: 10,
                lineHeight: 0.5,
                marginTop: 20,
              },
              {
                text: `${invoiceDetails.companyPostNumber}\n\n`,
                fontSize: 12,
                bold: true,
                alignment: "left",
                letterSpacing: 10,
                lineHeight: 0.5,
                marginTop: 20,
              },
              {
                text: `${invoiceDetails.companyCity}\n\n`,
                fontSize: 12,
                bold: true,
                alignment: "left",
                letterSpacing: 10,
                lineHeight: 0.5,
                marginTop: 20,
              },
              {
                text: `${invoiceDetails.companyCountry}\n\n`,
                fontSize: 12,
                bold: true,
                alignment: "left",
                letterSpacing: 10,
                lineHeight: 0.5,
                marginTop: 20,
              },
            ],
            fontSize: 12,
            marginRight: 20,

            marginTop: 25,
            bold: false,
            alignment: "left",
            color: "#333",
            height: 60,
            lineHeight: 0.7,
            letterSpacing: 50,
          },
        ],
      },
      "\n\n",
      {
        table: {
          headerRows: 1,
          widths: ["50%", "*", "*", "*"],
          body: dataArray,
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === 1 || i === node.table.body.length ? 1 : 0.1;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 1 : 0;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === 1 || i === node.table.body.length
              ? "black"
              : "#f1f1f1";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length
              ? "white"
              : "white";
          },
        },
      },

      {
        columns: [
          {
            text: `${translator("Total(NET)")} :`,
            marginTop: 15,
            marginBottom: 5,
            width: "*",
            bold: true,
            fontSize: 13,
          },
          {
            text: new Intl.NumberFormat("en", {
              style: "currency",
              currency: "GBP",
            }).format(Number(invoiceDetails.totalAmount)),
            marginTop: 15,
            marginBottom: 5,
            width: "*",
            alignment: "right",
            bold: true,
            fontSize: 13,
          },
        ],
      },
      invoiceDetails.vat
        ? {
            columns: [
              {
                text: `${translator("VAT")} :`,
                marginTop: 1,
                marginBottom: 15,
                width: "*",
                bold: true,
                fontSize: 13,
              },
              {
                text: new Intl.NumberFormat("en", {
                  style: "currency",
                  currency: "GBP",
                }).format(Number(invoiceDetails.totalAmount) * 0.2),
                marginTop: 1,
                marginBottom: 5,
                width: "*",
                alignment: "right",
                bold: true,
                fontSize: 13,
              },
            ],
          }
        : "\n",
      invoiceDetails.vat
        ? {
            columns: [
              {
                text: `${translator("Total")} :`,
                marginTop: 1,
                marginBottom: 15,
                width: "*",
                bold: true,
                fontSize: 15,
              },
              {
                text: new Intl.NumberFormat("en", {
                  style: "currency",
                  currency: "GBP",
                }).format(
                  Number(invoiceDetails.totalAmount) * 0.2 +
                    Number(invoiceDetails.totalAmount)
                ),
                marginTop: 1,
                marginBottom: 15,
                width: "*",
                alignment: "right",
                bold: true,
                fontSize: 15,
              },
            ],
          }
        : "\n",
    ],
    footer: function (currentPage, pageCount) {
      return {
        table: {
          widths: ["100%"],
          body: [
            [
              {
                text: `${translator("FooterPrintText")}`,
                alignment: "center",
                style: "normalText",
                fontSize: 10,
                margin: [0, 0, 0, 0],
              },
            ],
          ],
        },
        layout: "noBorders",
        fillColor: "#517da7",
        color: "#fff",
      };
    },
  };
  if (sendMail) {
    const pdfDocGenerator = pdfMake.createPdf(dd);
    pdfDocGenerator.getBase64((data) => {
      invoiceService.sendMail(invoiceDetails.id, data).then((res) => {
        //console.log(res);
      });
    });
  } else {
    pdfMake.createPdf(dd).open();
  }
}
