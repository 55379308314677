import { carUrl } from "../Config/Url";
import axios from "axios";

export const carService = {
  getCars,
  deleteCar,
  addCar,
  getById,
  updateCar,
};

async function getCars(companyId, isPaid, isExported, isArrived,locationId) {
  return axios
    .get(
      `${carUrl}?companyId=${companyId}&isPaid=${
        isPaid ? isPaid : ""
      }&isExported=${isExported ? isExported : ""}&isArrived=${
        isArrived ? isArrived : ""
      }&locationId=${locationId}`
    )
    .then((res) => res)
    .catch((err) => err.response);
}

async function deleteCar(id) {
  return axios
    .delete(`${carUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function getById(id) {
  return axios
    .get(`${carUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function addCar(data) {
  console.log("dd",data);
  var formData = new FormData();

  formData.append("Name", data.name);
  formData.append("RegNum", data.regNum);
  formData.append("ChasisNum", data.chasisNum);
  formData.append("EngineNum", data.engineNum);
  formData.append("EngineSize", data.engineSize);
  formData.append("Kw", data.kw);
  formData.append("Color", data.color);
  formData.append("CompanyId", Number(data.companyId.value));
  formData.append("SubCustomer", data.subCustomer);
  formData.append("IsPaid", data.isPaid);
  formData.append("IsArrived", data.isArrived);
  formData.append("IsExported", data.isExported);
  formData.append("LotNumber", data.lotNumber);
  formData.append("LocationId", data.locationId.value);

  let arrayTest = Array.from(data.images);
  arrayTest.forEach((element) => {
    formData.append("Images", element);
  });

  return axios
    .post(carUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res)
    .catch((err) => err.response);
}

async function updateCar(id, data) {
  console.log("dd",data);
  var formData = new FormData();

  formData.append("Data.Name", data.name);
  formData.append("Data.RegNum", data.regNum);
  formData.append("Data.ChasisNum", data.chasisNum);
  formData.append("Data.EngineNum", data.engineNum);
  formData.append("Data.EngineSize", data.engineSize);
  formData.append("Data.Kw", data.kw);
  formData.append("Data.Color", data.color);
  formData.append("Data.SubCustomer", data.subCustomer);
  formData.append("Data.IsPaid", data.isPaid);
  formData.append("Data.IsArrived", data.isArrived);
  formData.append("Data.IsExported", data.isExported);
  formData.append("Data.LotNumber", data.lotNumber);
  formData.append("Data.LocationId", data.locationId.value);

  let arrayTest = Array.from(data.images);
  arrayTest.forEach((element) => {
    formData.append("Data.Images", element);
  });

  return axios
    .put(`${carUrl}/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res)
    .catch((err) => err.response);
}
