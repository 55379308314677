import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import CardWrap from "../Common/CardWrap";
import ErrorMsg from "../Common/ErrorMsg";
import { globalCarService } from "../../Services/GlobalCarService";
import { useTranslation } from "react-i18next";
import ResultModal from "../Common/ResultModal";
import DatePicker from "react-datepicker";
import Select from "react-select";
const CarAdd = () => {
  const { t: translator } = useTranslation();
  const history = useHistory();

  const { register, handleSubmit, errors, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const [resultModal, setResultModal] = useState({
    show: false,
    title: "",
    text: "",
  });
  const options = [
    // { value: 1, label: "Superadmin" },
    { value: 1, label: "Location1" },
    { value: 2, label: "Location2" },
  ];

  const onSubmit = (data) => {
    console.log(data);
    globalCarService
      .addCar(data)
      .then((res) =>
        res.data.success
          ? setResultModal({
              show: true,
              title: "Success",
              text: "SuccessText",
            })
          : setResultModal({
              show: true,
              title: "Error",
              text: "ErrorText",
            })
      )
      .catch((err) => console.log(err));
  };

  return (
    <CardWrap title={translator("GlobalCarAddHeader")}>
      <form onSubmit={handleSubmit(onSubmit)} className="row">
        <div className="col-lg-4">
          <div className="form-group">
            <label>{translator("GlobalCarFormDate")}</label>
            <Controller
              as={DatePicker}
              control={control}
              className="form-control"
              valueName="selected"
              autocomplete="off"
              dateFormat="dd.MM.yyyy"
              name="datePurchased"
              rules={{ required: true }}
            />
            {errors.datePurchased && (
              <ErrorMsg text={translator("RequiredField")} />
            )}
          </div>
          <div className="form-group">
            <label>{translator("GlobalCarPruchasedFrom")}</label>
            <input
              name="pruchasedFrom"
              className="form-control"
              type="text"
              ref={register({ required: true })}
            />
            {errors.regNum && <ErrorMsg text={translator("RequiredField")} />}
          </div>
          <div className="form-group">
            <label>{translator("GlobalCarFormlotNumber")}</label>
            <input
              name="lotNumber"
              className="form-control"
              type="text"
              ref={register({ required: true })}
            />
            {errors.chasisNum && (
              <ErrorMsg text={translator("RequiredField")} />
            )}
          </div>
          <div className="form-group">
            <label>{translator("GlobalCarFormRegNumber")}</label>
            <input
              name="regNumber"
              className="form-control"
              type="text"
              ref={register({ required: true })}
            />
            {errors.engineNum && (
              <ErrorMsg text={translator("RequiredField")} />
            )}
          </div>
        </div>
        <div className="col-lg-4">
          <div className="form-group">
            <label>{translator("GlobalCarFormBrand")}</label>
            <input
              name="brand"
              className="form-control"
              type="text"
              ref={register({ required: true })}
            />
            {errors.engineSize && (
              <ErrorMsg text={translator("RequiredField")} />
            )}
          </div>
          <div className="form-group">
            <label>{translator("GlobalCarFormModel")}</label>
            <input
              className="form-control"
              type="text"
              name="model"
              ref={register({ required: true })}
            />
            {errors.kw && <ErrorMsg text={translator("RequiredField")} />}
          </div>
          <div className="form-group">
            <label>{translator("GlobalCarFormColor")}</label>
            <input
              className="form-control"
              type="text"
              name="colour"
              ref={register({ required: true })}
            />
            {errors.color && <ErrorMsg text={translator("RequiredField")} />}
          </div>
          <div className="form-group">
            <label>{translator("GlobalCarFormEngineCode")}</label>
            <input
              type="text"
              name="engineCode"
              ref={register()}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>{translator("GlobalCarFormMileage")}</label>
            <input
              type="text"
              name="mileage"
              ref={register()}
              className="form-control"
            />
          </div>
        </div>

        <div className="col-lg-4">
          <div className="form-group">
            <label>{translator("GlobalCarFormBodyType")}</label>
            <input
              name="bodyType"
              className="form-control"
              type="text"
              ref={register({ required: true })}
            />
            {errors.engineSize && (
              <ErrorMsg text={translator("RequiredField")} />
            )}
          </div>
          <div className="form-group">
            <label>{translator("GlobalCarFormPaintCode")}</label>
            <input
              name="paintCode"
              className="form-control"
              type="text"
              ref={register({ required: true })}
            />
            {errors.engineSize && (
              <ErrorMsg text={translator("RequiredField")} />
            )}
          </div>
          <div className="form-group">
            <input
              type="checkbox"
              name="isArrived"
              ref={register}
              //defaultChecked={data.isArrived}
            />
            <label>{translator("InvoiceFormArrived")}</label>
          </div>
          <div className="form-group">
            <label>{translator("CarFormImages")}</label>
            <input
              className="form-control"
              type="file"
              name="images"
              ref={register()}
              multiple
            />
          </div>

          <div className="form-group">
              <label>{translator("CarLocation")}</label>
              <Controller
                as={Select}
                control={control}
                name="locationId"
                rules={{ required: true }}
                placeholder={translator("LocationDropdownOption")}
                options={options}
              />
              {errors.companyId && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
        </div>
        <div
          className="container-fluid"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={() => history.goBack()}
          >
            {translator("ButtonCancel")}
          </button>
          &nbsp;
          <button className="btn btn-primary" type="submit">
            {translator("ButtonSave")}
          </button>
        </div>
      </form>

      <ResultModal
        show={resultModal.show}
        title={resultModal.title}
        text={resultModal.text}
        handleHide={() => setResultModal({ ...resultModal, show: false })}
        handleSubmit={() => history.push("/global-cars")}
      />
    </CardWrap>
  );
};

export default CarAdd;
